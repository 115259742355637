import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../shared/BreadCrumb";
import { useApi } from "@providers/api";
import { useUser } from "@providers/user";
import SelectAccount from "./components/SelectAccount";

export default function CreateUser() {
  const api = useApi();
  const { offices } = useUser();
  const [createMany, setCreateMany] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const superUser = selectedRole === "super";
  const salesRepUser = selectedRole === "sales_rep";

  useEffect(() => {
    api.get("/accounts").then((response) => {
      setAccounts(response);
    });
  }, []);

  const createUser = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const user = {
      name: formData.get("name"),
      email: formData.get("email").toLowerCase(),
      password: formData.get("password"),
      password_confirmation: formData.get("password_confirmation"),
      role: formData.get("role"),
      phone: formData.get("phone"),
      ext: formData.get("ext"),
    };

    if (superUser) {
      user.account_ids = [];
    } else {
      if (!selectedAccounts.length) {
        return alert("Please select an account");
      }

      if (salesRepUser) {
        user.account_ids = selectedAccounts;
      } else {
        user.account_ids = [selectedAccounts[0]];
      }
    }

    api.post("/users", { user }).then((response) => {
      if (response.error) {
        alert("An error occurred, please try again later.");
      } else if (!response.id && response.length > 0) {
        alert(response.join("\n"));
      } else {
        console.log(response);
        form.reset();

        if (!createMany) {
          window.location = "/admin/users/" + response.id;
        }
      }
    });
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[["Admin", "/admin"], ["Users", "/admin/users"], "Create User"]}
      />
      <form onSubmit={createUser}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input required type="text" name="name" id="name" />
          </div>
          <div className="column">
            <label htmlFor="email">Email</label>
            <input
              required
              type="email"
              name="email"
              id="email"
              style={{ textTransform: "lowercase" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="phone">Phone</label>
            <input type="tel" name="phone" id="phone" />
          </div>
          <div className="column">
            <label htmlFor="ext">Extension</label>
            <input type="text" name="ext" id="ext" />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="role">Role</label>
            <select
              required
              name="role"
              id="role"
              onChange={(e) => {
                setSelectedRole(e.target.value);
                setSelectedAccounts([]);
              }}
            >
              <option value="">Select Role</option>
              <option value="admin">Admin</option>
              <option value="doctor">Doctor</option>
              <option value="accounting">Accounting</option>
              <option value="sales_rep">Sales Rep</option>
              <option value="super">⚠️ Super User</option>
            </select>
          </div>
          <SelectAccount
            accounts={accounts}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            superUser={superUser}
            salesRepUser={salesRepUser}
          />
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="password">Password</label>
            <input
              minlength="6"
              required
              type="password"
              name="password"
              id="password"
            />
          </div>
          <div className="column">
            <label htmlFor="password">Confirm Password</label>
            <input
              required
              type="password"
              name="password_confirmation"
              id="password_confirmation"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <button type="submit">Create User</button>
          </div>
          <div className="column text-right">
            <label>
              <input
                type="checkbox"
                checked={createMany}
                onChange={() => setCreateMany(!createMany)}
              />
              &nbsp;&nbsp;Check this box if you intend to create many users
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}

const SelectOffice = ({ selectedRole, offices, accounts }) => {
  if (selectedRole === "super") {
    return null;
  } else {
    return (
      <>
        <label htmlFor="account_id">Account</label>
        <select required name="account_id" id="account_id">
          <option value="">Select Account</option>
          {accounts?.map((account) => (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          ))}
        </select>
      </>
    );
  }
};
