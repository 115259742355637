import React, { useState } from "react";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useUser } from "@providers/user";
import API_URL from "@env/api";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useUser();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset error message
    setError("");

    // Simple validation
    if (!email.includes("@")) {
      setError("Invalid email address.");
      return;
    }

    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.ok) {
          return data;
        } else {
          setError(data.error || "Invalid Credentials");
        }
      })
      .then((data) => {
        if (!data) return;

        window.localStorage.setItem("token", data.token);
        user.setUser(data.user);
        user.setToken(data.token);
        user.setTokenValidatedAt(Date.now());
        user.setTokenValid(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleForgotPassword = (email) => {
    setIsModalOpen(false);
    fetch(`${API_URL}/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.ok) {
          return data;
        } else {
          setError(data.error || "Invalid email address.");
        }
      })
      .then((data) => {
        if (!data) return;

        setError("Password reset email sent.");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <div className="center-middle" style={{ height: "100vh" }}>
        <div>
          <img
            width="300px"
            height="88.33px"
            style={{ margin: "0 auto 30px", display: "block" }}
            src="/EWCC-HS.png"
            alt="EWCC Logo"
          />
          {error && <div className="callout alert">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="column text-left">
                <label>
                  Email
                  <input
                    type="email"
                    style={{ textTransform: "lowercase" }}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="column text-left">
                <label>
                  Password
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="column text-left">
                <button type="submit" className="button expanded">
                  Login
                </button>
              </div>
            </div>
          </form>
          <a onClick={() => setIsModalOpen(true)}>Forgot Password?</a>
        </div>
      </div>
      <ForgotPasswordModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleForgotPassword}
      />
    </>
  );
};

export default Login;
